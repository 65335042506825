<template>
  <div class="page-column" style="padding: 0 32px">
    <perfect-scrollbar>
      <div class="scheduler-nav">
        <div @click="$refs.Scheduler.prev()" class="btn-nav">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.707 4.293a1 1 0 010 1.414L9.414 12l6.293 6.293a1 1 0 01-1.414 1.414l-7-7a1 1 0 010-1.414l7-7a1 1 0 011.414 0z"/></svg>
        </div>
        <div @click="$refs.Scheduler.next()" class="btn-nav">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.293 19.707a1 1 0 010-1.414L14.586 12 8.293 5.707a1 1 0 011.414-1.414l7 7a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0z"/></svg>
        </div>
      </div>

      <VueScheduler
        :activeView="scheduleView"
        :events="schedulePrepareFilterList"
        @timeClickedGlobal="timeClickedGlobal"
        @eventClickedGlobal="eventClickedGlobal"
        @eventRemoveClicked="eventRemove"
        ref="Scheduler"
      />
    </perfect-scrollbar>
  </div>
</template>
<script>
import ScheduleEventCreate from '@component/Schedule/ScheduleEventForm';
import ScheduleEventView from '@component/Schedule/ScheduleEventView';
import VueScheduler from '@plugin/vue-calendar-schedule/src/components/VueScheduler';
import { communityGetSelected } from '@store/community.store';

import {
  scheduleGetList,
  ScheduleItemAdd,
  ScheduleItemEdit,
  deleteScheduleItem,
  fetchScheduleList,
  getScheduleView,
  scheduleFilter,
} from '@store/schedule.store';

export default {
  name: 'Schedule',
  components: {
    VueScheduler,
  },
  data() {
    return {
      scheduleList: scheduleGetList(),
    };
  },
  watch: {
    scheduleFilterDate(newVal) {
      if (newVal) {
        this.$refs.Scheduler.goToDate(newVal);
      }
    },
  },
  computed: {
    scheduleFilterDate() {
      return scheduleFilter.value.date;
    },
    scheduleView() {
      return getScheduleView().value;
    },
    schedulePrepareFilterList() {
      let value = this.schedulePrepareList;
      if (
        !scheduleFilter.value.scheduleType.meeting &&
        !scheduleFilter.value.scheduleType.task &&
        !scheduleFilter.value.scheduleType.reminder
      ) {
        //         value = this.schedulePrepareList;
      } else {
        value = value.filter((item) => {
          return scheduleFilter.value.scheduleType[item.type];
        });
      }

      //

      const scheduleItemCommunity = communityGetSelected();

      if (
        scheduleItemCommunity.value &&
        scheduleItemCommunity.value.id !== null
      ) {
        value = value.filter((item) => {
          return item.community?.id === scheduleItemCommunity.value.id;
        });
      }

      return value;
    },
    schedulePrepareList() {
      const scheduleList = scheduleGetList();
      const newList = scheduleList.value.map((eventData) => {
        let eventObj = {
          // TODO: Удалить id после интеграции с backend
          // id: `${Date.now().toString(36) + Math.random().toString(36).substr(2)}`,
          id: eventData.id,
          //
          name: eventData.title,
          // date: eventData.datePeriod.start ? eventData.datePeriod.start : eventData.dateItem, // TODO: delete
          // startTime: eventData.datePeriod.start ? eventData.datePeriod.start : eventData.dateItem,
          // endTime: eventData.datePeriod.end ? eventData.datePeriod.end : eventData.dateItem,
          type: eventData.type || 'meeting', // TODO: use theme
          status: eventData.status
            ? eventData.status.toLowerCase()
            : this.$enum.SCHEDULE_EVENT_STATUS.WAITING.toLowerCase(),
          //
          userData: eventData,
          community: eventData.community,
        };

        if (eventData.datePeriod) {
          eventObj.date = eventData.datePeriod.start;
          eventObj.startTime = eventData.datePeriod.start;
          eventObj.endTime = eventData.datePeriod.end;
        }

        if (eventData.dateItem) {
          eventObj.date = eventData.dateItem;
          eventObj.startTime = eventData.dateItem;
          eventObj.endTime = eventData.dateItem;
        }

        return eventObj;
      });

      return newList;
    },
  },
  created() {
    fetchScheduleList();
    const eventId = this.$route.query.event_id;
    if (eventId) {
      this.eventClickedGlobal({ userData: { id: eventId } });
    }
  },
  methods: {
    /**
     * @param {Object}  dataObj
     * @param {Date}    dataObj.date
     * @param {Number}  dataObj.time
     */
    timeClickedGlobal(dataObj) {
      //
      const date = dataObj.date;
      const time = dataObj.time;
      //
      let startTime = DateTime(date);
      let endTime = DateTime(date);

      //if(dataObj.time) {
      startTime = startTime.hour(time); // TODO: fix .minute(0).second(0);
      endTime = endTime.hour(time).add(1, 'h'); // TODO: 23:20 + 1 час - что будет
      //}

      this.openModalEventCreate({
        datePeriod: {
          start: startTime,
          end: endTime,
        },
        dateItem: startTime,
      });
    },
    eventClickedGlobal(data) {
      RequestManager.Schedule.getScheduleItem({
        scheduleId: data.userData.id,
      }).then((originScheduleItem) => {
        this.openModalEventView(originScheduleItem);
      });
    },

    eventRemove(data) {
      let modalMessage = 'Are you sure to delete this event?';
      if (data.idOfSeries && data.id === data.idOfSeries) {
        modalMessage =
          'You are going to delete this events and others. Are you sure?';
      }
      let modal = this.$dialog.prompt.delete('Remove event?', modalMessage);
      modal.then((res) => {
        if (res && res.action === 'OK') {
          deleteScheduleItem(data.id);
        }
        modal = null;
      });
      return modal;
    },

    setEventStatus(eventId, status) {
      return RequestManager.Schedule.setStatusScheduleItem({
        scheduleId: eventId,
        status: status,
      }).then(() => {
        fetchScheduleList();
      });
    },

    openModalEventCreate(data) {
      //
      let modal = null;
      const closeModal = () => {
        modal && modal.close();
      };
      const props = {
        // data
        formData: data,
        // events
        onSubmit: async (submitObj) => {
          const scheduleItem = Object.assign({}, submitObj);

          let participants = [];

          let shareObj = {
            skipAccessValidation: false,
            users: [],
            communities: [],
          };

          const scheduleItemCommunity = communityGetSelected();
          if (scheduleItemCommunity.value.id !== null) {
            Object.assign(scheduleItem, { id: scheduleItemCommunity.value.id });
          } else {
            scheduleItem.community = null;
          }

          // TODO: Refactor it
          if (scheduleItem.to.length) {
            scheduleItem.to.forEach((userObj) => {
              shareObj.users.push({
                type: 'R',
                id: userObj.id,
              });
            });
          }
          
          if (scheduleItemCommunity.value?.adminId) {
            shareObj.users.push({
              type: 'R',
              id: scheduleItemCommunity.value.adminId,
            });
          }

          const scheduleItemFilesShared = [];

          if (
            scheduleItem &&
            scheduleItem.fileList &&
            scheduleItem.fileList.length
          ) {
            for (let i = 0; i < scheduleItem.fileList.length; i++) {
              const file = scheduleItem.fileList[i];
              try {
                const response = await RequestManager.FileStorage.add({
                  file: file,
                });

                scheduleItemFilesShared.push({
                  bucketname: response.bucketname,
                  filename: response.filename,
                  url: response.url,
                });

                if (response) {
                  RequestManager.FileStorage.shareFile({
                    bucketName: response.bucketname,
                    filename: response.filename,
                    shareObj: shareObj,
                  });
                }
              } catch (err) {
                console.error(err);
              }
            }
          }

          if (!scheduleItem.fileList) {
            scheduleItem.fileList = [];
          }
          scheduleItem.files = scheduleItem.fileList.concat(
            scheduleItemFilesShared
          );

          ScheduleItemAdd(scheduleItem);
          closeModal();
        },
      };
      //
      modal = this.$dialog.open(ScheduleEventCreate, props, {
        group: 'modal',
        theme: 'event',
        close: true,
      });
      modal.then(() => {
        modal = null;
      });
    },

    openModalEventView(data) {
      this.$router.push({ query: { event_id: data.id } });
      //
      let modal = null;
      const closeModal = () => {
        modal && modal.close();
      };
      const props = {
        // data
        // TODO: fix and check
        ...data,
        // events
        onActionEdit: () => {
          closeModal();
          this.openModalEventEdit(data).then((res) => {
            if (res && res.success) {
              return;
            }
            this.openModalEventView(data);
          });
        },
        onActionRemove: () => {
          closeModal();
          this.eventRemove(data).then((res) => {
            if (res && res.action === 'OK') {
              return;
            }
            this.openModalEventView(data);
          });
        },
        onSetEventStatus: (eventStatus) => {
          this.setEventStatus(data.id, eventStatus).then((res) => {
            closeModal();
          });
        },
        onOpenMeeting: (id) => {
          this.$router
            .push({
              name: this.$routeName.MEETING,
              params: { id },
            })
            .then(() => {
              closeModal();
            });
        },
      };
      //
      modal = this.$dialog.open(ScheduleEventView, props, {
        group: 'modal',
        theme: 'event-view',
        remove: true,
        edit: true,
        close: true,
      });
      modal.then(() => {
        this.$router.push({ query: {} });
        modal = null;
      });
    },

    openModalEventEdit(originScheduleItem) {
      //
      let modal = null;

      const closeModal = (editRes = {}) => {
        modal && modal.close(editRes);
      };
      const props = {
        // data
        formData: originScheduleItem,
        // events
        onSubmit: async (submitObj) => {
          const scheduleItem = Object.assign({}, originScheduleItem);

          let shareObj = {
            skipAccessValidation: false,
            users: [],
            communities: [],
          };

          const scheduleItemCommunity = communityGetSelected();
          if (scheduleItemCommunity.value.id !== null) {
            Object.assign(scheduleItem, { id: scheduleItemCommunity.value.id });
          } else {
            scheduleItem.community = null;
          }

          if (scheduleItem.to.length) {
            scheduleItem.to.forEach(({ id }) => {
              shareObj.users.push({
                type: 'R',
                id,
              });
            });
          }

          if (scheduleItemCommunity.value?.adminId) {
            shareObj.users.push({
              type: 'R',
              id: scheduleItemCommunity.value.adminId,
            });
          }

          const scheduleItemFilesShared = [];
          if (submitObj?.fileList?.length) {
            for (let i = 0; i < submitObj.fileList.length; i++) {
              const file = submitObj.fileList[i];
              try {
                const response = await RequestManager.FileStorage.add({
                  file: file,
                });

                scheduleItemFilesShared.push({
                  bucketname: response.bucketname,
                  filename: response.filename,
                  url: response.url,
                });

                if (response) {
                  RequestManager.FileStorage.shareFile({
                    bucketName: response.bucketname,
                    filename: response.filename,
                    shareObj: shareObj,
                  });
                }
              } catch (err) {
                console.error(err);
              }
            }
          }

          scheduleItem.files = scheduleItem.files.concat(
            scheduleItemFilesShared
          );

          Object.assign(scheduleItem, submitObj);
          ScheduleItemEdit(originScheduleItem.id, scheduleItem);
          closeModal({ success: true });
        },
      };
      //
      modal = this.$dialog.open(ScheduleEventCreate, props, {
        group: 'modal',
        theme: 'event',
        close: true,
      });
      modal.then(() => {
        modal = null;
      });
      return modal;
    },
  },
};
</script>

<style lang="scss" scoped>
.scheduler-nav {
  display: flex;
  margin-bottom: 8px;
}

.btn-nav {
  @include icon-size(2);
  margin-right: 14px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--navback-background);
  border: none;
  cursor: pointer;
  & > svg {
    fill: var(--navback-color);
  }
  &:first-of-type {
    margin-right: 6px;
  }
}
</style>
